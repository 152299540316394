<template>
  <div class="decompte">
    <transition name="fade">
      <div v-if="afficher && chiffre" class="chiffre">{{chiffre}}</div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      afficher: true,
      chiffre: 3,
    };
  },
  mounted() {
    this.chiffre = 3;
    this.avancerDecompte();
  },
  methods: {
    perduOuGagne() {
      let w = Math.ceil(Math.random() * 10000000) % 10;
      if (w > 4) {
        this.$router.push("resultat");
      } else {
        this.$router.push("perdu");
      }
    },
    avancerDecompte() {
      setTimeout(() => {
        this.afficher = false;
        ion.sound.play("bip");    
        setTimeout(() => {
          this.chiffre--;
          this.afficher = true;
          if (this.chiffre) {
            this.avancerDecompte();
          } else {
            this.perduOuGagne();
          }
        }, 500);
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.decompte {
  position: absolute;
  .chiffre {
    font-size: 50vh;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: #ffffff;
    text-shadow: 0 -1px 4px #fff, 0 -2px 10px #ff0, 0 -10px 20px #ff8000,
      0 -18px 40px #f00;
  }
}
</style>